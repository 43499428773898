import { Box } from '@chakra-ui/react'
import React from 'react'

function ImageGrid({ isGridStart = false, isGridEnd = false, data, onClick }) {
    return (
        <Box
            h={isGridStart ? 'auto' : (isGridEnd ? 'auto' : { base: '130px', xs: '150px', sm: '170px', md: '190px', lg: '210', xl: '230px', xxl: '250px' })}
            gridColumnStart={isGridStart ? '3' : (isGridEnd ? '1' : 'unset')}
            gridRowStart={isGridStart ? '1' : (isGridEnd ? '1' : 'unset')}
            gridRowEnd={isGridStart ? '3' : (isGridEnd ? '3' : 'unset')}
            backgroundImage={`url('${data.url}')`}
            backgroundPosition='center'
            backgroundSize='cover'
            border='1px solid #F2F2F2'
            onClick={onClick}
            cursor='pointer'
        />
    )
}

export default ImageGrid