const ROUTE_URL = {
    HOME: '/',
    LOGIN: '/login',
    REGISTER: '/register',
    NEWS: '/news',
    NEWS_DETAIL: '/news/:id',
    GALLERY: '/gallery',
    GALLERY_DETAIL: '/gallery/:id',
    ORGANIZATION: '/organization',
    PROFILE: '/profile',
    PROFILE_ECARD: '/profile/e-card',
    PROFILE_ASPIRASI: '/profile/aspiration-management',
    PROFILE_SURVEY: '/profile/survey-polling-vote',
    PROFILE_CARD: '/card/profile',
    SURVEY_PAGE: '/survey/:id',
    SURVEY_ANALYTIC: '/survey/analytic',
    SURVEY_ANALYTIC_DETAIL: '/survey/analytic/:id',
    FORGOT_PASSWORD: '/forgot-password',
}

export default ROUTE_URL
