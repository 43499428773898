import React from 'react'
import { Route, Routes } from 'react-router-dom'
import GalleryDetail from '../pages/Gallery/GalleryDetail'
import ROUTE_URL from './url'
import ErrorPage from '../pages/ErrorPage'

function Router() {
    return (
        <Routes>
            <Route path={ROUTE_URL.HOME} element={<GalleryDetail />} />

            <Route path='/*' element={<ErrorPage code='404' message='Page not found' />} />
        </Routes>
    )
}

export default Router
