import axios from 'axios'
import Cookies from 'universal-cookie/es6'
import jwt_decode from 'jwt-decode'

const cookies = new Cookies()
const getAuthTokenCookie = () => cookies.get('auth_token') || null

const xhr = (props) => {
    const config = {
        baseURL:
            process.env.REACT_APP_API_BASE_URL +
            process.env.REACT_APP_API_NOCODE_URL +
            process.env.REACT_APP_API_COMPANY_ID
    }

    const auth = props?.auth ? props.auth : false
    if (auth && getAuthTokenCookie()) {
        config.headers = {}
        config.headers.Authorization = getAuthTokenCookie()
    }

    return axios.create(config)
}

const setAuthSession = (token) => {
    const decodedToken = jwt_decode(token)

    cookies.set('auth_token', token, {
        path: '/',
        expires: new Date(decodedToken.exp * 1000)
    })
}

const destroyAuthSession = () => {
    cookies.remove('auth_token', { path: '/' })
}

export { xhr, cookies, getAuthTokenCookie, setAuthSession, destroyAuthSession }
