import {
    Box,
    Flex,
    Icon,
    Image,
} from '@chakra-ui/react'
import { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide, } from 'swiper/react'
import { FaTimes } from 'react-icons/fa'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import './ImageLightbox.css'
import { useEffect } from 'react'

function ImageLightbox({ data, initial, setIsShow }) {
    useEffect(() => {
        document.body.style.overflow = 'hidden'

        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [])

    return (
        <Box
            position='fixed'
            top='0'
            left='0'
            w='100vw'
            h='100vh'
            onClick={(e) => e.target.getAttribute('data-target') === 'swiper-slide' && setIsShow(false)}
            zIndex='999'
        >
            <Flex
                w='100%'
                h='100%'
                position='relative'
                backdropFilter='blur(15px)'
                background='rgba(0, 0, 0, .5)'
            >
                <Flex
                    position='absolute'
                    top='.75rem'
                    right='.75rem'
                    onClick={() => setIsShow(false)}
                    cursor='pointer'
                    color='primary'
                    background='#fff'
                    height='30px'
                    width='30px'
                    borderRadius='30px'
                    align='center'
                    justify='center'
                    zIndex='99'
                >
                    <Icon
                        as={FaTimes}
                        fontSize='20px'
                    />
                </Flex>
                <Box
                    as={Swiper}
                    loop={true}
                    slidesPerView={1}
                    initialSlide={initial}
                    modules={[Navigation, Pagination]}
                    grabCursor={false}
                    navigation={true}
                    centeredSlides={true}
                    pagination={{
                        clickable: true
                    }}
                    allowTouchMove={true}
                >
                    {
                        data.map((v, i) => (
                            <SwiperSlide key={i}>
                                <Flex
                                    w='100%'
                                    h='100%'
                                    justify='center'
                                    align='center'
                                    data-target='swiper-slide'
                                >
                                    <Image
                                        src={v.url}
                                        maxW='100vw'
                                        maxH='100vh'
                                    />
                                </Flex>
                            </SwiperSlide>
                        ))
                    }
                </Box>
            </Flex>
        </Box>
    )
}

export default ImageLightbox