import React, { useState } from 'react'
import Wrapper from '../../components/Wrapper'
import ImageGrid from './components/ImageGrid'
import ImageLightbox from './components/ImageLightbox/ImageLightbox'
import Skeleton from 'react-loading-skeleton'
import { format } from 'date-fns'
import { useEffect } from 'react'
import { useMutation } from 'react-query'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { getGalleryDetailApi } from '../../api/request/gallery'
import {
    Container,
    Flex,
    Grid,
    Icon,
    Stack,
    Text
} from '@chakra-ui/react'
import Head from '../../components/Head'
import { FaChevronRight } from 'react-icons/fa'
import ROUTE_URL from '../../router/url'
import {
    nay_1,
    nay_2,
    nay_3,
    nay_4,
    nay_5,
    nay_6,
    nay_7,
    nay_8,
    nay_9,
    nay_10,
    nay_11,
    nay_12,
    nay_13,
    nay_14,
    nay_15,
    nay_16,
    nay_17,
} from '../../assets'

function GalleryDetail() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [initialLightBox, setInitialLightBox] = useState(0)
    const [isShow, setIsShow] = useState(false)
    const [imageDatas, setImageDatas] = useState(null)

    const handleAssembleImageDatas = (data) => {
        const imageData = []
        data?.forEach((v, i) => {
            if (i % 5 === 0) {
                imageData.push([])
                imageData[imageData.length - 1].push(v)
                return
            }

            imageData[imageData.length - 1].push(v)
        })
        setImageDatas(imageData)
    }

    const handleGetGalleryDetail = async () => {
        return await getGalleryDetailApi({ id })
    }

    const galleryDetail = useMutation(handleGetGalleryDetail, {
        onSuccess: (resp) => handleAssembleImageDatas(resp)
    })

    const imagess = [
        {
            name: '1',
            url: nay_1
        },
        {
            name: '2',
            url: nay_2
        },
        {
            name: '3',
            url: nay_3
        },
        {
            name: '4',
            url: nay_4
        },
        {
            name: '5',
            url: nay_5
        },
        {
            name: '6',
            url: nay_6
        },
        {
            name: '7',
            url: nay_7
        },
        {
            name: '8',
            url: nay_8
        },
        {
            name: '9',
            url: nay_9
        },
        {
            name: '10',
            url: nay_10
        },
        {
            name: '11',
            url: nay_11
        },
        {
            name: '12',
            url: nay_12
        },
        {
            name: '13',
            url: nay_13
        },
        {
            name: '14',
            url: nay_14
        },
        {
            name: '15',
            url: nay_15
        },
        {
            name: '16',
            url: nay_16
        },
        {
            name: '17',
            url: nay_17
        },
    ]

    useEffect(() => {
        handleAssembleImageDatas(imagess)
    }, [])

    return (
        <>
            <Head title={'Nayla Intan Kamilia'} />
            <Wrapper>
                <Container maxW='container.xl'>
                    <Stack
                        py={{ base: '3rem', lg: '6rem' }}
                        spacing='2rem'
                    >
                        <Stack spacing={{ base: '.15rem', md: '.5rem' }}>
                            {
                                imageDatas && imageDatas.map((g, i) => (
                                    <Grid
                                        key={i}
                                        templateColumns='1fr 1fr 1fr'
                                        gap={{ base: '.15rem', md: '.5rem' }}
                                    >
                                        {
                                            g.map((d, j) => {
                                                const parentIndex = i + 1
                                                const isGridStart = j === 0 ? (parentIndex % 2 === 0 && g.length === 5 ? true : false) : false
                                                const isGridEnd = j === 0 ? (parentIndex % 2 !== 0 && g.length === 5 ? true : false) : false

                                                return (<ImageGrid
                                                    key={j}
                                                    isGridStart={isGridStart}
                                                    isGridEnd={isGridEnd}
                                                    data={d}
                                                    onClick={() => {
                                                        setInitialLightBox(j)
                                                        setIsShow(true)
                                                    }}
                                                />)
                                            })
                                        }
                                    </Grid>
                                ))
                            }
                        </Stack>
                    </Stack>
                </Container>
                {
                    isShow && (<ImageLightbox
                        data={imagess}
                        initial={initialLightBox}
                        setIsShow={setIsShow}
                    />)
                }
            </Wrapper>
        </>
    )
}

export default GalleryDetail