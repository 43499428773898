import { Box } from '@chakra-ui/react'
import React from 'react'

function Wrapper({ children }) {
    return (
        <Box pt='pt-wrapper'>
            {children}
        </Box>
    )
}

export default Wrapper