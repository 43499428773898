import { xhr } from "../config"

export const getListGalleryApi = async ({ page, limit }) => {
    try {
        const res = await xhr({ auth: true }).post('/portal/gallery/get', { page, limit })

        if (!res.data.error) return res.data.data
        throw res.data.message
    } catch (error) {
        throw error
    }
}

export const getGalleryDetailApi = async ({ id }) => {
    try {
        const res = await xhr({ auth: true }).post('/portal/gallery/get/detail', { id })

        if (!res.data.error) return res.data.data
        throw res.data.message
    } catch (error) {
        throw error
    }
}