import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Router from './router/Router'
import { useColorMode } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

const App = () => {
    const { colorMode } = useColorMode()
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return (
        <SkeletonTheme
            baseColor={colorMode === 'dark' && '#202020'}
            highlightColor={colorMode === 'dark' && '#444'}
        >
            <Router />
        </SkeletonTheme>
    )
}

export default App
