import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import ROUTE_URL from '../router/url'

function Head({ title }) {
    const { pathname } = useLocation()

    // const Title = pathname === ROUTE_URL.HOME ? title : `${title} - ${process.env.REACT_APP_NAME}`

    return (
        <Helmet>
            {title && <title>{title}</title>}
        </Helmet>
    )
}

export default Head