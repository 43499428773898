import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
    config: {
        initialColorMode: 'light',
        useSystemColorMode: false,
    },
    breakpoints: {
        xs: '414px',
        sm: '576px',
        md: '768px',
        lg: '992px',
        xl: '1200px',
        xxl: '1400px',
    },
    fonts: {
        heading: '"Poppins", sans-serif',
        body: '"Poppins", sans-serif',
    },
    sizes: {
        'scrollbar-width': '.5rem',
        'nav-height': '65px'
    },
    space: {
        'pt-wrapper': 'var(--chakra-sizes-nav-height)',
        'scrollbar-width': '.5rem'
    },
    semanticTokens: {
        colors: {
            'chakra-body-bg': {
                _light: '#FAFBFC'
            },
            'chakra-body-bg-secondary': {
                _light: 'linear-gradient(141.34deg, #F5F0FF 0%, #EFF6FE 50.36%, #EDFAF5 100%)'
            },
            'chakra-body-text': {
                _light: '#515458'
            },
            'primary': {
                _light: '#00005D'
            },
            'primary-lighten': {
                _light: '#0070F0'
            },
            'title-text-color': {
                _light: '#17181A'
            },
            'scrollbar-bg': {
                _light: '#F2F2F2'
            },
            'scrollbar-thumb': {
                _light: '#AFAFAF'
            },
        }
    },
    components: {
        Button: {
            variants: {
                primary: props => ({
                    background: 'primary',
                    color: 'chakra-body-bg',
                    _hover: {
                        background: '#00005dc9',

                        _disabled: {
                            background: 'primary'
                        }
                    }
                })
            }
        },
        Link: {
            baseStyle: {
                _hover: {
                    textDecoration: 'none'
                }
            }
        }
    }
})

export default theme
